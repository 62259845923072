import React from "react";
import {

	HeaderImage,
	Paragraph,
	Slider,
	ImgBlockRows,
	Cards,
	ContactCall,
	BorderPattern,

} from "../components/rows";

function Africa() {
	return (
		<>
			{
				<HeaderImage
					src_background="africa-46512.jpg"
					text="African Adventures&hellip;"
					img_height="70vh"
					class_text="col-auto text-light fw-med  px-5 mx-auto pb-2 h1"
					text_background_color="rgba(160, 82, 19,.6)"
				/>
			}
			{<BorderPattern img_src="border-pattern.jpg" alt_src="" />}
			<div className="spacer"></div>
			<div className="bg-dark ">
				{
					<Paragraph
						//////container
						class_Container="container pt-5 "
						class_Container_Title="row text-center mt-0 mx-auto "
						//////Title

						class_Title="h3 fw-light pt-0 text-light"
						content_Title="Discover the Diversity, Natural Beauty & Cultures of Africa" //////container
						//////Paragraph container

						class_Container_Paragraph="row text-center mt-4 w-85 px-3 mx-auto "
						//////Paragraph 1

						class_Paragraph_1="h6 fw-light lh-lg text-light"
						content_Paragraph_1=" Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae luctus metus libero eu augue. Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, fntum et, dapibus sed, urna."
						//////Paragraph 2

						class_Paragraph_2="h6 fw-light lh-lg text-light hidden"
						content_Paragraph_2=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis in! hidden"
						//////Paragraph 3

						class_Paragraph_3="h6 fw-light lh-lg text-light hidden"
						content_Paragraph_3=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis in!"
					/>
				}

				{
					<Slider classContainer='container mb-5 '
						class_row="row w-50 mx-auto p-4 pt-0"
						class_col="col"
						class_title_1="h4 pt-0 text-light"
						class_title_2="h4 pt-0 text-light"
						class_title_3="h4 pt-0 text-light"
						class_title_4="h4 pt-0 text-light"
						class_title_5="h4 pt-0 text-light"
						class_title_6="h4 pt-0 text-light"
						class_title_7="h4 pt-0 text-light"
						class_title_8="h4 pt-0 text-light"
						class_paragraph_1="p fw-light lh-lg text-light"
						class_paragraph_2="p fw-light lh-lg text-light"
						class_paragraph_3="p fw-light lh-lg text-light"
						class_paragraph_4="p fw-light lh-lg text-light"
						class_paragraph_5="p fw-light lh-lg text-light"
						class_paragraph_6="p fw-light lh-lg text-light"
						class_paragraph_7="p fw-light lh-lg text-light"
						class_paragraph_8="p fw-light lh-lg text-light"
						alt_1="img"
						alt_2="img"
						alt_3="img"
						alt_4="img"
						alt_5="img"
						alt_6="img"
						alt_7="img"
						alt_8="img"
						title_1="lorem ipsum"
						title_2="lorem ipsum"
						title_3="lorem ipsum"
						title_4="lorem ipsum"
						title_5="lorem ipsum"
						title_6="lorem ipsum"
						title_7="lorem ipsum"
						title_8="lorem ipsum"
						paragraph_1="lorem ipsum some more"
						paragraph_2="lorem ipsum some more"
						paragraph_3="lorem ipsum some more"
						paragraph_4="lorem ipsum some more"
						paragraph_5="lorem ipsum some more"
						paragraph_6="lorem ipsum some more"
						paragraph_7="lorem ipsum some more"
						paragraph_8="lorem ipsum some more"
						image_1="images/africa-P1150624_fs.jpg"
						image_2="images/africa-02_fs.jpg"
						image_3="images/africa-P1150634_fs.jpg"
						image_4="images/africa-gallery150_fs.jpg"
						image_5="images/africa-P1140264_fs.jpg"
						image_6="images/africa-DSC_0251_fs.jpg"
						image_7="images/africa-P1150963_fs.jpg"
						image_8="images/africa-gallery154_fs.jpg" ></Slider>
				}
			</div>
			<div className="spacer"></div>
			<div className="spacer"></div>
			{<BorderPattern img_src="border-pattern.jpg" alt_src="" />}
			<div className="bg-brown">
				{
					<Paragraph
						//////container
						class_Container="container pt-5 "
						class_Container_Title="row text-center mt-0 mx-auto "
						//////Title

						class_Title="h4 pt-0 text-light hidden"
						content_Title="Professional Trip Designing for the Inquisitive, Independent & Intrepid" //////container
						//////Paragraph container

						class_Container_Paragraph="row text-center mt-4 w-85 px-3 mx-auto "
						//////Paragraph 1

						class_Paragraph_1="p fw-light text-light"
						content_Paragraph_1="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo."
						//////Paragraph 2

						class_Paragraph_2="p fw-light lh-lg hidden"
						content_Paragraph_2=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis in!"
						//////Paragraph 3

						class_Paragraph_3="p fw-light lh-lg hidden"
						content_Paragraph_3=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis in!"
					/>
				}

				{
					<ImgBlockRows
						class_Container="container p-0  "
						class_row="row g-0  w-75 mx-auto"
						class_col_1="col"
						class_col_2="col"
						class_col_3="col hidden"
						class_col_4="col hidden"
						class_col_5="col hidden"
						class_col_6="col hidden"
						class_labels="mt-4 "
						class_img="img-fluid w-100 "
						class_label="text-center fst-italic text-light"
						image_1="sized-488x450-Vegetation_Africa.gif"
						alt_1="image"
						label_1="Lorem Ipsom"
						image_2="sized-488x450-taryn-elliott-4253928.jpg"
						alt_2="image"
						label_2="Lorem Ipsom"
						image_3="sized_180-DSC01669_fs.jpg"
						alt_3="image"
						label_3="Lorem Ipsom"
						image_4="sized_180-DSC01669_fs.jpg"
						alt_4="image"
						label_4="Lorem Ipsom"
						image_5="sized_180-DSC01669_fs.jpg"
						alt_5="image"
						label_5="Lorem Ipsom"
						image_6="sized_180-DSC01669_fs.jpg"
						alt_6="image"
						label_6="Lorem Ipsom"
					/>
				}
				<div className="spacer"></div>

				{
					<ContactCall
						class_Container="container mt-0 mb-0  pb-0 "
						class_row="row justify-content-center"
						class_link="decorate-none"
						link_target="tel:+1-503-296-7913"
						class_Paragraph_1="col-auto text-light  border border-1 text-end h6 p-3"
						class_Paragraph_2="col-auto text-light  border border-1 bg-green text-start h6 p-3"
						content_Paragraph_1="To Arrange Your Adventure of a Lifetime, Call:"
						content_Paragraph_2="+1 (503) 296-7913"
				/>
				}
				<div className="spacer"></div>
				<div className="spacer"></div>

				{<BorderPattern img_src="border-pattern.jpg" alt_src="" />}
			</div>

			<div className="bg-light ">
				<div className="spacer"></div>
				<div className="spacer"></div>

				{
					<Cards
						class_Container="container bg-beige p-0  mt-0 pb-5"
						class_row="row g-2 g-xl-2  m-0  p-0 mx-auto pb-5"
						class_col_1="col my-3 "
						class_col_2="col my-3 "
						class_col_3="col my-3 "
						class_col_4="col my-3 "
						class_col_5="col my-3 "
						class_img="rounded img-fluid m-auto img-zoom"
						class_card="card card-size mb-4  bg-transparent mb-lg-0 "
						class_card_body="card-body card-offset bg-white  rounded shadow"
						class_drop_menu="card-drop-menu"
						class_drop_item="card-drop-menu-item"
						image_1="thumb-img-7.jpg"
						alt_1="image"
						image_2="card-ship.jpg"
						alt_2="image"
						image_3="thumb-img-9.jpg"
						alt_3="image"
						image_4="thumb-img-10.jpg"
						alt_4="image"
						image_5="thumb-img-11b.jpg"
						alt_5="image"
						class_header_1=" h4 card-header1 fw-bold ps-1"
						class_header_2=" h6 card-header2 fw-bold ps-1"
						class_ul_list="h6 p-1 ps-4 list-group"
						class_li_list="mb-1  border-0 card-link-bullet"
						class_text_1="p fw-light lh-base card-link "
						class_text_2="p fw-light lh-base card-link text-light"
						header_1=""
						header_2=""
						header_3=""
						header_4=""
						header_5=""
						header_6=""
						subtitle_1=""
						subtitle_2=""
						subtitle_3=""
						subtitle_4=""
						subtitle_5=""
						subtitle_6=""
						col_1_text_1="africa"
						col_1_href_1="./africa"
						col_1_text_2="africa"
						col_1_href_2="./africa"
						col_1_text_3="africa"
						col_1_href_3="./africa"
						col_1_text_4="africa"
						col_1_href_4="./africa"
						col_1_text_5="africa"
						col_1_href_5="./africa"
						col_1_text_6="africa"
						col_1_href_6="./africa"
						col_2_text_1="africa"
						col_2_href_1="./africa"
						col_2_text_2="africa"
						col_2_href_2="./africa"
						col_2_text_3="africa"
						col_2_href_3="./africa"
						col_2_text_4="africa"
						col_2_href_4="./africa"
						col_2_text_5="africa"
						col_2_href_5="./africa"
						col_2_text_6="africa"
						col_2_href_6="./africa"
						col_3_text_1="africa"
						col_3_href_1="./africa"
						col_3_text_2="africa"
						col_3_href_2="./africa"
						col_3_text_3="africa"
						col_3_href_3="./africa"
						col_3_text_4="africa"
						col_3_href_4="./africa"
						col_3_text_5="africa"
						col_3_href_5="./africa"
						col_3_text_6="africa"
						col_3_href_6="./africa"
						col_4_text_1="africa"
						col_4_href_1="./africa"
						col_4_text_2="africa"
						col_4_href_2="./africa"
						col_4_text_3="africa"
						col_4_href_3="./africa"
						col_4_text_4="africa"
						col_4_href_4="./africa"
						col_4_text_5="africa"
						col_4_href_5="./africa"
						col_4_text_6="africa"
						col_4_href_6="./africa"
						col_5_text_1="africa"
						col_5_href_1="./africa"
						col_5_text_2="africa"
						col_5_href_2="./africa"
						col_5_text_3="africa"
						col_5_href_3="./africa"
						col_5_text_4="africa"
						col_5_href_4="./africa"
						col_5_text_5="africa"
						col_5_href_5="./africa"
						col_5_text_6="africa"
						col_5_href_6="./africa"
					/>

				}
			</div>
			{<BorderPattern img_src="border-pattern.jpg" alt_src="" />}

		</>
	);
}

export default Africa;
